// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-activity-jsx": () => import("./../../../src/templates/Activity.jsx" /* webpackChunkName: "component---src-templates-activity-jsx" */),
  "component---src-templates-documents-jsx": () => import("./../../../src/templates/Documents.jsx" /* webpackChunkName: "component---src-templates-documents-jsx" */),
  "component---src-templates-links-jsx": () => import("./../../../src/templates/Links.jsx" /* webpackChunkName: "component---src-templates-links-jsx" */),
  "component---src-templates-related-jsx": () => import("./../../../src/templates/Related.jsx" /* webpackChunkName: "component---src-templates-related-jsx" */),
  "component---src-templates-series-jsx": () => import("./../../../src/templates/Series.jsx" /* webpackChunkName: "component---src-templates-series-jsx" */)
}

